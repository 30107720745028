import { route } from 'preact-router';
import { ReviewConfigContext } from './ReviewConfigContext';
import { Button, Prompt, Screen } from './components';

const onClick = reviewFeedbackId => () =>
  route(`/${reviewFeedbackId}/prompt/0`);

export default props => {
  const { reviewFeedbackId } = props;
  return (
    <ReviewConfigContext.Consumer>
      {({ config }) => (
        <Screen id="thanks">
          <Prompt>Thanks for your feedback!</Prompt>
          {config.review_mode !== 'FEEDBACK' &&
            config.reviewSites &&
            config.reviewSites.length > 0 && (
              <Button id="public-review" onClick={onClick(reviewFeedbackId)}>
                LEAVE PUBLIC REVIEW
              </Button>
            )}
        </Screen>
      )}
    </ReviewConfigContext.Consumer>
  );
};
