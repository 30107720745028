import { ReviewConfigContext } from '../ReviewConfigContext';

export const Prompt = props => (
  <ReviewConfigContext.Consumer>
    {({ config }) => (
      <div
        id={props.id}
        style={{
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '26px',
          lineHeight: '30px',
          textAlign: 'center',
          maxWidth: '275px',
          marginBottom: '20px',
          color: config.body_text_color,
        }}
      >
        {props.children}
      </div>
    )}
  </ReviewConfigContext.Consumer>
);
