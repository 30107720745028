import { Prompt, Screen } from './components';

export default () => (
  <Screen noHeader id="error">
    <Prompt>
      <strong>Thanks for your interest in leaving a review.</strong>
    </Prompt>
    <Prompt>The review you're looking for may have expired.</Prompt>
  </Screen>
);
